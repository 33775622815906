import Swiper from "swiper";
/**
 * import "../../node_modules/swiper/swiper-bundle.min.css"; //this is not adding the css to the compiled files therefore it needs to be added to vendor.scss
 */

import SwiperCore, { Navigation, Pagination } from "swiper/core";
// configure Swiper to use modules
SwiperCore.use([Navigation, Pagination]);

const swiper = new Swiper(".swiper-container", {
	// Optional parameters
	loop: false,
	slidesPerView: 1,
	centeredSlides: false,
	spaceBetween: 10,

	breakpoints: {
		// when window width is >= 320px
		320: {
			slidesPerView: 1,
			spaceBetween: 24,
		},
		// when window width is >= 480px
		689: {
			slidesPerView: 2,
			spaceBetween: 24,
		},
		// when window width is >= 1024px
		1024: {
			slidesPerView: 3,
			spaceBetween: 28,
		},
		// when window width is >= 1440px
		1599: {
			slidesPerView: 4,
			spaceBetween: 32,
		},
	},

	// Navigation arrows
	navigation: {
		nextEl: ".sb-next",
		prevEl: ".sb-prev",
	},
});

// hover desktop navigation arrows on magazines
function addListeners() {
	let allSliders = document.getElementsByClassName("swiper-container");
	
	for (let index = 0; index < allSliders.length; index++) {
		
		allSliders[index].closest(".component-wrapper").addEventListener('mouseenter', function() {
			allSliders[index].getElementsByClassName("swiper-navbtn-wrap")[0].getElementsByClassName("sb-next")[0].classList.add("sb-next--active");
			allSliders[index].getElementsByClassName("swiper-navbtn-wrap")[0].getElementsByClassName("sb-prev")[0].classList.add("sb-prev--active");
			
		});	
		
		allSliders[index].closest(".component-wrapper").addEventListener('mouseleave', function() {
			allSliders[index].getElementsByClassName("swiper-navbtn-wrap")[0].getElementsByClassName("sb-next")[0].classList.remove("sb-next--active");
			allSliders[index].getElementsByClassName("swiper-navbtn-wrap")[0].getElementsByClassName("sb-prev")[0].classList.remove("sb-prev--active");
			
		});
	}

	console.log("added Swiper listeners for adding navigation.");
}

if (document.readyState === "complete") {
	ready();
} else {
	document.addEventListener("DOMContentLoaded", ready);
}

function ready() {
	addListeners();
}